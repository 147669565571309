@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
    /*Font CSSProps*/
    --fontFamily: "Kanit", sans-serif;
    --primaryFontColor: #ccc;
    --colorGrey1: #cfcfcf;
    /*Colors CSSProps*/
    --Sprimary: #0bb4ff;
    --Ssecondary: #061953;
    --Swhite: #fff;
    --formFontColor: #7a7a7a;
    --formFontColorDark:#4a4a4a;
    --Adminprimary: #4f5c94;
    --Adminsecondary: #6c78a9;
    --Adminprimarylight: #cdd6ff;
    --Adminprimarydark: #091b58;
    --Admindanger: #fc3261;
    --Admindangerdark: #ff003c;
    --Admindangerlight: #f886a1;
    --Adminsuccess: #1ed600;
    --Adminretry: #74011c;
    --Adminretrydark: #5e0016;
    --Adminretrylight: #af3a55;
    --Webprimary: #0b1a58;
}

/* styles */
@import "assets/styles/button.css";
@import "assets/styles/form.css";
@import "assets/styles/utils.css";
/*@import "assets/styles/layout.css";*/

/**********************************************************COMMON**/
.logo {
    position: fixed;
    left: 10px;
}
.text-align-center {
    text-align: center;
}
.text-align-end {
    text-align: end;
}
/**********************************************************TEXT-CASE RELATED**/
.firstCap {
    display: inline-block;
}
.firstCap:first-letter {
    text-transform: capitalize;
}
.firstCap:after {
    padding: 0 2px;
    float: left;
}
/**********************************************************ROW-RELATED**/
.row-box {
    width: 100%;
    display: flex;
    padding-bottom: 1%;
}
.col-50 {
    width: 50%;
}
.col-50-end {
    width: 50%;
    text-align: end;
}
/**********************************************************PADDING**/
.pd-tp-2 {
    padding-top: 2%;
}
.pd-bt-2 {
    padding-bottom: 2%;
}
/**********************************************************APP**/
.app-container {
    height: auto;
    border-radius: 10px;
    border: 1px solid rgba(79, 92, 148, 0.4);
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    padding: 30px 50px 30px 50px;
    display: flow-root;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    margin-bottom: 1.2rem;
}
.app-icon {
    font-size: 13px;
    padding-left: 2px;
}
.app-btn-alter {
    margin: unset;
    display: revert;
}
/**********************************************************APP-HEADER**/
.app-h2-header {
    color: var(--Adminprimarydark);
}
.app-h3-header {
    color: var(--Adminsecondary);
}
/**********************************************************WEB**/
.web-authform {
    width: 100%;
    height: 100vh;
    background: #333;
    position: relative;
}
.web-authform-left {
    width: 100%;
    height: 100vh;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(206.96deg, var(--Webprimary) 1.84%, #5b7f65 100%);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.web-authform-left img {
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    width: 600px;
    height: 600px;
    object-fit: contain;
}
.web-authform-right {
    width: 100%;
    height: 100vh;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.web-authform-right h1 {
    font-size: 35px;
    font-weight: 400;
    color: var(--Webprimary);
    margin-bottom: 1.2rem;
}
.web-authform-right h1 span {
    font-weight: 700;
}
.web-authform-container {
    width: 65%;
    min-height: 10rem;
    height: auto;
}
.web-authform-newacc-text {
    font-weight: 400;
    font-size: 14px;
    color: rgba(58, 68, 59, 0.8) !important;
    display: flex;
    justify-content: center;
}
.web-authform-newacc-text span {
    color: var(--Webprimary) !important;
    font-weight: 700;
}
/*********************************************************************ANTD-OVERWRITTING***/
.ant-spin {
    color: var(--Adminprimarydark);
}
.ant-spin-dot-item {
    background-color: var(--Adminprimarydark);
}

/* responsive media queries */
@media screen and (max-width: 576px){
    .app-container {
        padding: 7% 7%;
    }
}
@media screen and (max-width: 768px){
    .app-container {
        padding: 7% 7%;
    }
}