.faqpg-box .card-container p {
    margin: 0;
}
.faqpg-box .card-container>.ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -16px;
}
.faqpg-box .card-container>.ant-tabs-card .ant-tabs-content>.ant-tabs-tabpane {
    padding: 16px;
    background: #fff;
}
.faqpg-box .card-container>.ant-tabs-card>.ant-tabs-nav::before {
    display: none;
}
.faqpg-box #components-tabs-demo-card-top .code-box-demo {
    padding: 24px;
    overflow: hidden;
    background: #f5f5f5;
}
.faqpg-box .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active,
.faqpg-box .ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab-active {
    border: 1px solid var(--Adminprimarylight);
    background: var(--Adminprimarylight);
}
.faqpg-box .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    border: none;
    background: #ffffff;
}
.faqpg-box .ant-tabs-top > .ant-tabs-nav::before {
    border: none;
}
.faqpg-box .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--Adminprimary);
}
.faqpg-box .ant-tabs {
    color: var(--Adminsecondary);
}
.faqpg-box .ant-tabs-tab-btn:focus, .faqpg-box .ant-tabs-tab-remove:focus, .faqpg-box .ant-tabs-tab-btn:active, .faqpg-box .ant-tabs-tab-remove:active,
.faqpg-box .ant-tabs-tab-btn:hover {
    color: var(--Adminprimary);
}
.faqpg-box .ant-tabs-nav-list {
    width: 100%;
}
.faqpg-box .ant-tabs-tab {
    width: 24.5%;
    justify-content: center;
}
.faqpg-box .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: var(--Adminprimarydark);
}
.faqpg-box .ant-collapse-content {
    color: var(--Adminprimary);
    font-size: 14px;
}
.faqpg-box .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: none;
    background-color: #fafafa;
    margin-bottom: 1%;
}
.faqpg-box .ant-collapse-borderless {
    background-color: #ffffff;
}
.faqpg-box .ant-collapse {
    font-size: 17px;
}