.ant-layout-footer {
    padding: 24px 0px;
    color: #fff;
    font-size: 14px;
    background: var(--Webprimary);
}
.ant-layout-footer .subscribe-card {
    width: 100%;
    min-height: 10rem;
    height: auto;
    background: #fff;
    border-radius: 10px;
    margin-top: -100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}
.subscribe-card h1 {
    font-size: 28px;
    font-weight: 700;
    color: #001a49;
}
.subscribe-card h6 {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 26, 73, 0.7);
    float: left;
}
.ant-layout-footer p {
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
    padding-top: 1.2rem;
}
@media screen and (max-width: 768px) {
    .subscribe-card h1 {
        font-size: 22px;
        max-width: 350px;
        font-weight: 700;
        color: #001a49;
        /* float: left; */
        text-align: left;
    }
    .subscribe-card h6 {
        font-size: 14px;
    }
    .subscribe-card {
        margin-top: -50px;
    }
}
