@import "./../../App.css";

/**************************************************************************Page Css**/
.featuresList {
    padding: 1em 0;
}
.featuresMain {
    padding: 19.4vh 0;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: rgb(255 255 255);
    font-size: 16pt;
    background-image: url("https://res.cloudinary.com/doozydeal/image/upload/v1661427560/new/homebg_mkwf9o.png");
}
/**************************************************************************Antd List Rework**/
.ant-list-header {
    font-size: 5em;
}
.ant-list-split .ant-list-item {
    font-size: 20pt !important;
    border-bottom: none !important;
}
.ant-list-bordered {
    border: none !important;
    border-radius: 0px !important;
}
.ant-list-split .ant-list-header {
    border-bottom: none !important;
}
/*.ant-btn-primary {
    border-color: var(--Sprimary) !important;
    background: var(--Sprimary) !important;
}
.ant-btn-primary:hover {
    border-color: var(--Ssecondary) !important;
    background: var(--Ssecondary) !important;
} */

/* sign up */
.signup-left {
    position: fixed;
    top: 0;
    left: 0;
    width: 45%;
    height: 100%;
    background: #fff;
    overflow: hidden;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(206.96deg, rgba(11, 26, 88, 0.87) 1.84%, rgba(0, 0, 0, 0.54) 100%), url(../../assets/signup-bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-origin: content-box;
}
.signup-left img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 500px;
    object-fit: contain;
}
.signup-right {
    position: absolute;
    top: 0;
    left: 45%;
    width: 55%;
    min-height: 100vh;
    height: auto;
    background: #ccc;
}
.signup-container {
    padding: 50px;
    position: relative;
    background: #fff;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.signup-container h1 {
    font-size: 35px;
    font-weight: 400;
    color: var(--Webprimary);
    margin-bottom: 1.2rem;
}
.signup-container h1 span {
    font-weight: 700;
}
/* responsive media queries */
@media screen and (max-width: 768px) {
    .signup-left {
        display: none;
    }
    .signup-right {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 100vh;
        height: auto;
    }
}
