.verifypg .web-authform-container {
    width: 33%;
}

@media screen and (max-width: 576px){
    .verifypg .web-authform-container {
        width: 85%;
    }
}
@media screen and (max-width: 768px){
    .verifypg .web-authform-container {
        width: 75%;
    }
}
@media screen and (max-width: 996px){
    .verifypg .web-authform-container {
        width: 65%;
    }
}
@media screen and (min-width: 1200px){
    .verifypg .web-authform-container {
        width: 33%;
    }
}