/****************************************************************TYPE 1**/
.app-primary-btn.ant-btn {
    min-width: 13rem;
    height: 45px;
    background: var(--Adminprimary);
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
}
.app-primary-btn.ant-btn:hover,
.app-primary-btn.ant-btn:focus {
    background: var(--Adminprimary) !important;
    border-color: var(--Adminprimary);
    color: #fff !important;
}
.app-primary-btn-outline.ant-btn {
    border: 1px solid var(--Adminprimary);
    color: var(--Adminprimary);
    background: #fff;
}
/****************************************************************TYPE 2**/
.app-custom-btn-outline {
    border-radius: 4px;
    background: #fff;
    color: var(--Adminprimarydark) !important;
    outline: none;
    border: 1px solid var(--Adminprimarydark);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    display: block;
    margin: 0 auto;
    min-width: 10rem;
}
.app-custom-btn-outline:hover {
    background: var(--Adminprimary);
    color: #fff !important;
    border: 1px solid var(--Adminprimary);
}
.app-custom-btn-outline:focus {
    border: 1px solid var(--Adminprimary);
}
/****************************************************************TYPE 3**/
.app-custom-btn {
    border-radius: 4px;
    background: var(--Adminprimary);
    color: #fff !important;
    outline: none;
    border: 1px solid var(--Adminprimary);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    display: block;
    margin: 0 auto;
    min-width: 10rem;
}
.app-custom-btn:hover,
.app-custom-btn:focus,
.app-custom-btn:active {
    background: var(--Adminprimarydark);
    color: #fff !important;
    border: 1px solid var(--Adminprimarydark);
}
.app-custom-btn-disable {
    border-radius: 4px;
    background: var(--Adminprimarylight) !important;
    color: #fff !important;
    outline: none;
    border: 1px solid var(--Adminprimarydark);
    transition: all 0.2s ease-in-out;
    min-width: 10rem;
}
/****************************************************************TYPE 4**/
.app-danger-btn {
    border-radius: 4px;
    background: var(--Admindanger);
    color: #fff !important;
    outline: none;
    border: 1px solid var(--Admindanger);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    display: block;
    margin: 0 auto;
    min-width: 10rem;
}
.app-danger-btn:hover,
.app-danger-btn:focus,
.app-danger-btn:active {
    background: var(--Admindangerdark);
    color: #fff !important;
    border: 1px solid var(--Admindangerdark);
}
.app-danger-btn-disable {
    border-radius: 4px;
    background: var(--Admindangerlight) !important;
    color: #fff !important;
    outline: none;
    border: 1px solid var(--Admindangerdark);
    transition: all 0.2s ease-in-out;
    min-width: 10rem;
}
.app-retry-btn {
    border-radius: 4px;
    background: var(--Adminretry);
    color: #fff !important;
    outline: none;
    border: 1px solid var(--Adminretry);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    display: block;
    margin: 0 auto;
    min-width: 10rem;
}
.app-retry-btn:hover,
.app-retry-btn:focus,
.app-retry-btn:active {
    background: var(--Adminretrydark);
    color: #fff !important;
    border: 1px solid var(--Adminretrydark);
}
/****************************************************************TYPE 5**/
.web-availability-btn.ant-btn {
    display: block;
    margin: 0 auto;
    padding: 6px 18px;
    background: #fff;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
}
.web-availability-btn.ant-btn:hover,
.web-availability-btn.ant-btn:focus,
.web-availability-btn.ant-btn:active {
    background: #fff;
    border-color: var(--Adminprimarydark) !important;
    color: var(--Adminprimarydark) !important;
}
/****************************************************************TYPE 6**/
.web-primary-button {
    display: block;
    border-radius: 10px;
    width: 100%;
    height: 45px;
    background: var(--Webprimary);
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.web-primary-button:hover {
    background: var(--Webprimary);
    border: none;
    color: #fff;
    outline: none;
}
.web-primary-button:focus {
    background: var(--Webprimary);
    border: none;
    color: #fff;
    outline: none;
}
/****************************************************************TYPE 7**/
.app-plain-btn {
    background: none;
    border: none;
    color: var(--Adminprimarydark);
    font-size: 12pt;
    font-weight: 600;
    cursor: pointer;
}
.app-plain-btn:hover,
.app-plain-btn:focus,
.app-plain-btn:active {
    color: var(--Adminprimary);
}