.listpg-address-container {
    padding-right: 2%;
}
.listpg-address-box {
    color: #4c4c4cd9;
}
.listpg-badge-box {
    display: flex;
    padding-bottom: 3%;
}
.listpg-item-box {
    color: #4c4c4cd9;
}
.listpg-order-item-2 {
    text-align: end;
}
.listpg-orderid {
    font-size: 11pt;
    color: var(--Adminprimary);
    font-weight: 600;
}
.listpg-track {
    font-size: 10pt;
    border-bottom: 1px dashed;
    color: var(--formFontColor);
}
.listpg-track:hover, .listpg-track:active {
    color: var(--formFontColorDark);
}
.listpg-trackbox {
    padding-bottom: 3%;
}

@media screen and (max-width: 768px) {
    .listpg-order-item-1 {
        padding: 2%;
    }
    .listpg-order-item-2 {
        padding: 3%;
        text-align: center;
    }
    .listpg-iteminfo {
        padding: 3%;
    }
}