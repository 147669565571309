/* antd header */
.ant-layout-header {
    height: 64px;
    padding: 0.5rem calc((100vw - 1000px) / 2);
    /* padding: 0 100px; */
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
    background: #fff;
    display: flex;
    justify-content: flex-start;
}
/* header logo */
.header-logo {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
}
.home-bars {
    display: none;
    color: #fff;
}
@media screen and (max-width: 768px) {
    .home-bars {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 5%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #333;
    }
}
.header-menu {
    display: flex;
    align-items: center;
    margin-right: -24px;
    width: 100vw;
    white-space: nowrap;
}
@media screen and (max-width: 768px) {
    .header-menu {
        display: none;
    }
}
.header-menu-link {
    color: rgba(11, 26, 88, 0.5);
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    padding: 0 1rem;
    font-size: 16px;
    font-weight: 500;
}
.link-active {
    position: relative;
    color: #001529;
}
.link-active::before {
    content: "\a0\a0\a0\a0\a0\a0\a0\a0";
    display: block;
    margin: 0 auto;
    position: absolute;
    /* text-decoration: underline; */
    width: 50px;
    top: 1px;
    overflow: hidden;
    color: #001529;
}
.header-menu-btn {
    display: flex;
    align-items: center;
    margin-right: 24px;
    justify-content: flex-end;
    width: 100vw;
}
.home-login-btn {
    border-radius: 4px;
    background: #fff !important;
    /* padding: 11px 22px; */
    color: var(--Webprimary) !important;
    outline: none;
    border: 1px solid #001529;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    display: block;
}
.home-login-btn:hover {
    background: var(--Webprimary) !important;
    color: #fff !important;
}
.home-signup-btn {
    border-radius: 4px;
    background: var(--Webprimary) !important;
    /* padding: 11px 22px; */
    color: #fff !important;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    display: block;
}
@media screen and (max-width: 768px) {
    .header-menu-btn {
        display: none;
    }
}
.responsive-navbar {
    display: none;
}
@media screen and (max-width: 768px) {
    .responsive-navbar {
        display: block;
        position: fixed;
        top: 0px;
        right: 0;
        width: 200px;
        height: 100%;
        transition: all 0.2s ease-in-out;
        background: #001529;
        z-index: 999;
    }
    .close-sidebar {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 8%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }
    .responsive-menu {
        padding-top: 64px;
        padding-left: 20px;
    }
    .responsive-menu-link {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
    }
}
