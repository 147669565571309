.orderpg-timeImg {
    top: 10px;
    position: relative;
}
.orderpg-serviceProvider {
    border-radius: 16px;
    box-shadow: 5px 8px 24px 5px rgba(208, 216, 243, 0.6);
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
    min-width: 550px;
    width: 800px;
}
.orderpg-loadmore-list {
    display: flex;
    justify-content: center;
}
.orderpg-img-box { 
    width: 250px;
    height: 70px;
    object-fit: contain;
}
.orderpg-form .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: var(--Adminprimary);
}
.orderpg-form .ant-steps-item-process .ant-steps-item-icon {
    border-color: var(--Adminsecondary);
}
.orderpg-form .ant-steps-item-finish .ant-steps-item-icon {
    background-color: var(--Adminprimarylight);
    border-color: var(--Adminsecondary);
}
.orderpg-form .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: var(--Adminsecondary);
}
.orderpg-form .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: var(--Adminsecondary);
}
.orderpg-serviceProvider h2 {
    font-size: 22px;
    font-weight: 700;
    margin-top: 12px;
    margin-left: 12px;
    text-transform: capitalize;
    border-left: 2px dashed var(--Adminprimarylight);
    padding-left: 8%;
    color: var(--Adminsecondary);
}

@media screen and (min-width: 0px) and (max-width: 576px) {
    .orderpg-serviceProvider {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 5px;
        margin-top: 5px;
        min-width: 400px;
        width: 450px;
    }
    .orderpg-img-box { 
        width: 200px;
        height: 46px;
        object-fit: contain;
    }
    .orderpg-provider h1 {
        margin-bottom: 0px;
    }
}

@media screen and (min-width: 577) and (max-width: 768px){
    .orderpg-serviceProvider {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 5px;
        margin-top: 5px;
        min-width: 550px;
        width: 620px;
    }
    .orderpg-img-box { 
        width: 240px;
        height: 55px;
        object-fit: contain;
    }
    .orderpg-provider h1 {
        margin-bottom: 0px;
    }
}