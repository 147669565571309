.Admin-menu.ant-menu .ant-menu-dark,
.Admin-menu.ant-menu-dark .ant-menu-item,
.Admin-menu.ant-menu-dark .ant-menu-item-group-title,
.Admin-menu.ant-menu-dark .ant-menu-item > a,
.Admin-menu.ant-menu-dark .ant-menu-item > span > a,
.Admin-menu.ant-menu.ant-menu-inline-collapsed .ant-menu-item-icon,
.Admin-menu.ant-menu.ant-menu-inline-collapsed .anticon {
    color: rgb(255 255 255) !important;
}
.Admin-menu .ant-menu-item {
    border-radius: 10px;
    background: var(--Adminsecondary) !important;
}
.Admin-menu .ant-menu-item-active, .Admin-menu .ant-menu-item-selected {
    background: var(--Adminprimarydark) !important;
}
.Admin-menu .ant-menu-item .ant-menu-item-icon {
    border-right: 1px solid #ffffff42;
    padding-right: 5%;
}
.Admin-menu .ant-menu.ant-menu-inline-collapsed .ant-menu-item-icon, .ant-menu.ant-menu-inline-collapsed .anticon {
    border: none;
}