.Admin-header {
    padding: 0rem calc((100vw - 1000px) / 2) !important;
    border-bottom: 2px solid var(--Adminprimary);
}
.Admin-header .logo {
    position: fixed;
    left: 10px;
    top: 2px;
}
.Admin-header-page-header {
    font-size: 18px;
    /*color: white; */
    color: var(--Adminprimary);
    font-weight: 700;
    left: 30px;
    position: absolute;
}
.Admin-header-profile {
    right: 0px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 32%;
    max-width: 200px;
}
.Admin-header-profile span:last-child {
    font-size: 18px;
    font-weight: 600;
    color: var(--Adminprimarydark);
}
.Admin-header-profile-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #ccc;
    object-fit: cover;
    position: relative;
}
.Admin-header-profile-img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/******************************************************************************** Antd CSS Over Writting*/
.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
    color: var(--Adminsecondary);
    font-weight: 600;
    background-color: #fff;
}
.ant-dropdown-menu-item:hover, .ant-dropdown-menu-item.ant-dropdown-menu-item-active {
    background-color: var(--Adminprimarylight);
}