.providerpg-vendorlogo {
    width: 160px;
    height: 125px;
    object-fit: contain;
}
.providerpg-card {
    height: 5rem;
    border-radius: 10px;
    border: 1px solid rgba(79, 92, 148, 0.4);
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    padding: 5px 20px 5px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    margin-bottom: 1.2rem;
}
.providerpg-card .providerpg-left {
    display: flex;
    align-items: center;
}
.providerpg-left img {
    width: 118px;
    height: 64px;
    object-fit: contain;
    border-radius: 10px;
}
.providerpg-left h2 {
    font-size: 22px;
    font-weight: 700;
    margin-top: 12px;
    margin-left: 12px;
    text-transform: capitalize;
    border-left: 2px dashed var(--Adminprimarylight);
    padding-left: 8%;
    color: var(--Adminsecondary);
}
.providerpg-card h4 {
    font-size: 14px;
    font-weight: 400;
    color: var(--Webprimary);
    display: block;
    margin: 0 auto;
}
.providerpg-card .select-btn {
    min-width: 13rem;
    height: 45px;
    background: #fff;
    border-radius: 10px;
    border: 1.5px solid rgba(76, 91, 153, 0.7);
    color: #4c5b99;
    font-size: 14px;
    font-weight: 600;
}
.providerpg-card .connected-btn {
    min-width: 13rem;
    height: 45px;
    background: var(--Adminsuccess);
    border-radius: 10px;
    color: #ffff;
    font-size: 14px;
    font-weight: 600;
}
.providerpg-card .connected-btn:hover,
.providerpg-card .connected-btn:focus {
    border-color: var(--Adminsuccess);
}
.providerpg-card-skeleton .ant-skeleton {
    display: inline-block;
    width: 100%;
}
.providerpg-card-skeleton .ant-skeleton-element .ant-skeleton-button {
    display: inline-block;
    vertical-align: top;
    background: rgba(190, 190, 190, 0.2);
    border-radius: 10px;
    width: 100%;
    min-width: 64px;
    height: 5rem;
    line-height: 32px;
}
.provider-list .confirm-details-skeleton p:first-child .ant-skeleton {
    display: inline-block;
    width: 13rem;
}
.provider-list .confirm-details-skeleton p:first-child .ant-skeleton .ant-skeleton-button {
    min-width: 13rem;
    height: 45px;
    border-radius: 10px;
}
/***********************************************************************responsive**/
@media screen and (max-width: 576px) {
    .providerpg-left img {
        width: 60px;
        height: 60px;
        object-fit: contain;
    }
    .providerpg-left h2 {
        font-size: 16px;
    }
    .providerpg-card .select-btn {
        min-width: 7rem;
        height: 45px;
    }
    .providerpg-card .connected-btn {
        min-width: 7rem;
        height: 45px;
    }
}
