@import "./../../App.css";

/* main section */
.home-main-section {
    position: relative;
    padding: 150px 0px 150px 0px;
    background-position: center center;
    display: flex;
    align-items: center;
    overflow: hidden;
    background: #fff;
}
.home-main-section::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 20%;
    width: 100%;
    height: 100%;
    background: linear-gradient(111.56deg, var(--Webprimary) 2.82%, #5b7f65 71.73%);
    border-radius: 0px 1500px 1500px 0px;
}
.home-main-section h5 {
    padding-top: 1.8rem;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 2px;
}
.home-main-section h1 {
    font-size: 36px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 1rem;
}
.home-main-section p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4;
    color: #fff;
}
.home-main-section button {
    border-radius: 4px;
    background: #fff;
    padding: 11px 22px;
    color: var(--Webprimary);
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    display: block;
}
.home-main-section .img-fluid {
    max-width: 100%;
    height: 400px;
    object-fit: contain;
    vertical-align: middle;
    border-style: none;
    align-self: flex-end;
    margin-left: 5rem;
}
.circle-main {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.55);
    z-index: 999;
    position: absolute;
    left: -25px;
    bottom: 50px;
}
.vertical-dot {
    position: absolute;
    width: 50px;
    height: 50px;
    object-fit: contain;
    top: -90px;
    left: 0;
}
.horizontal-dot {
    position: absolute;
    width: 50px;
    height: 50px;
    object-fit: contain;
    right: 0;
    bottom: -45px;
    transform: rotate(90deg);
}
/* feature section */
.feature-section {
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    background: #fff;
}
.feature-section h1 {
    font-size: 28px;
    font-weight: 700;
    color: var(--Webprimary);
    margin-bottom: 0.85rem;
    text-align: center;
}
.feature-section p {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 26, 73, 0.7);
    text-align: center;
    margin-bottom: 2rem;
}
.feature-section .feature-card {
    width: 100%;
    min-height: 8rem;
    height: auto;
    background: #fff;
    border-radius: 10px;
    border: 1px solid rgba(0, 26, 73, 0.02);
    box-shadow: 0px 25px 45px rgba(0, 26, 73, 0.05);
    position: relative;
    padding: 15px 15px 0px 15px;
    margin-bottom: 1rem;
}
.feature-card h1 {
    font-size: 18px;
    font-weight: 700;
    color: var(--Webprimary);
    margin-bottom: 0.5rem;
    text-align: left;
}
.feature-card h3 {
    position: absolute;
    font-size: 28px;
    color: rgba(0, 26, 73, 0.1);
    top: 0;
    right: 15px;
}
.feature-card p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    color: rgba(0, 26, 73, 0.7);
    text-align: justify;
}
/* conatct section */
.contact-section {
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
}
.contact-section h1 {
    font-size: 28px;
    font-weight: 700;
    color: var(--Webprimary);
    margin-bottom: 0.85rem;
    text-align: center;
}
.contact-section p {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 26, 73, 0.7);
    text-align: center;
    margin-bottom: 2rem;
}
.contact-form {
    width: 60%;
    min-height: 18rem;
    height: auto;
    margin-bottom: 3rem;
}
.home-custom-btn {
    border-radius: 4px;
    background: var(--Webprimary) !important;
    color: #fff !important;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    display: block;
    margin: 0 auto;
    min-width: 10rem;
}

/* availability section */
.availability-card {
    width: 100%;
    min-height: 15rem;
    height: auto;
    padding: 20px;
    background: #000;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.availability-container {
    padding: 10px 10px;
    /* background: var(--Webprimary); */
    border-radius: 10px;
}
.availability-card h1 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
}
.availability-card p {
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
}
.availability-card .delivery-partner-img {
    position: relative;
    width: 88px;
    height: 48px;
    border-radius: 10px;
    margin-bottom: 12px;
}
.availability-card .delivery-partner-img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}