.dashbrdpg-list .ant-card-body{
    padding: 12px;
}
.dashbrdpg-list .ant-card, .dashbrdpg-list .ant-card-bordered{
    border-radius: 15px;
    border: 1px solid #c3c4c7;
}
.dashbrdpg-list .dashbrdpg-list-item{
    padding-bottom: 1%;
}
.dashbrdpg-track {
    font-size: 9pt;
    border-bottom: 1px dashed;
    color: var(--formFontColor);
    margin-left: 5%;
}
.dashbrdpg-track:hover, .dashbrdpg-track:active {
    color: var(--formFontColorDark);
}
.dashbrdpg-address{
    color: var(--formFontColor);
}
.dashbrdpg-tooltip {
    font-size: 13pt;
    padding-left: 10px;
}
.dashbrdpg-a-tag{
    color: var(--Adminsecondary);
}
.dashbrdpg-a-tag:hover, .dashbrdpg-a-tag:active{
    color: var(--Adminprimarydark);
}
.dashbrdpg-carousel .ant-carousel .slick-dots li button {
    background: var(--Adminprimarydark);
}