/*************************************************************************WEB-TYPE-1**/
.web-availability-form-input.ant-input {
    width: 100%;
    height: 32px;
    /* color: #b0b4b1; */
    color: #fff;
    border: none;
    outline: none;
    background-color: transparent !important;
    font-size: 14px;
    font-weight: 600;
    padding: 0px 1rem;
    margin-bottom: 0;
    background: #fff;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-bottom: 1px solid #fff !important;
    border-radius: 0px;
}
.web-availability-form-input.ant-input::placeholder {
    color: #fff !important;
    opacity: 1;
    font-weight: 500;
    margin-left: -5px !important;
}
/*************************************************************************WEB-TYPE-2**/
.web-contact-form-input {
    width: 100%;
    height: 35px;
    /* min-width: 10rem; */
    color: #b0b4b1;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 12px;
    font-weight: 600;
    padding: 0px 1rem;
    margin-bottom: 0;
    background: #fff;
    border: 1px solid rgba(58, 68, 59, 0.3);
    border-radius: 3px;
}
.web-contact-form-textarea {
    width: 100%;
    color: rgba(41, 55, 95, 0.8);
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 12px;
    padding: 0px 1rem;
    margin-bottom: 0;
    background: #fff;
    border: 1px solid rgba(58, 68, 59, 0.3);
    border-radius: 3px;
}
/*************************************************************************WEB-TYPE-3**/
.web-custom-label {
    color: rgba(58, 68, 59, 0.4);
    font-size: 14px;
    font-weight: 600;
    padding: 0px !important;
}
/*************************************************************************WEB-TYPE-4**/
.app-custom-label {
    color: rgb(94 94 94);
    font-size: 14px;
    font-weight: 600;
    padding: 0px !important;
}
/*************************************************************************WEB-TYPE-5**/
.web-auth-form-input.ant-input,
.web-auth-form-input.ant-input-password {
    width: 100%;
    height: 32px;
    /* color: #b0b4b1; */
    color: var(--Webprimary);
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
    padding: 0px 1rem;
    margin-bottom: 0;
    background: #fff;
    border: 1px solid rgba(58, 68, 59, 0.3);
    border-radius: 5px;
}
.web-auth-form-input.ant-input:hover {
    border-color: rgba(58, 68, 59, 0.3);
    border-right-width: 1px;
}
.web-auth-form-line-item.ant-input-number-group-addon {
    background-color: #fff;
    border-top: 1px solid rgba(58, 68, 59, 0.3);
    border-bottom: 1px solid rgba(58, 68, 59, 0.3);
    border-left: 1px solid rgba(58, 68, 59, 0.3);
    border-radius: 5px 0px 0px 5px;
}
.web-auth-form-line-item .ant-input-number-input,
.web-auth-form-search-option.ant-select:not(.ant-select-customize-input) .ant-select-selector, 
.web-auth-form-search-option.ant-select:not(.ant-select-customize-input) .ant-select-selection-item {
    color: var(--Webprimary);
    font-size: 14px;
    font-weight: 600;
}
.web-auth-form-line-item .ant-input-number {
    border: 1px solid rgba(58, 68, 59, 0.3);
    border-radius: 5px;
}
.web-auth-form-search-option.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
/*************************************************************************WEB-TYPE-6**/
.app-form-input.ant-input,
.app-form-input.ant-picker,
.app-form-input.ant-input-number,
.app-form-input.ant-input-password {
    width: 100%;
    height: 32px;
    color: var(--formFontColor) !important;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: 600 !important;
    padding: 0px 1rem;
    margin-bottom: 0;
    background: #fff;
    border: 1px solid rgba(58, 68, 59, 0.3);
    border-radius: 5px;
}
.app-form-input.ant-input:hover {
    border-color: rgba(58, 68, 59, 0.3);
    border-right-width: 1px;
}
.app-form-input > .ant-picker-input > input {
    font-weight: 600;
    color: var(--formFontColor) !important;
}
.app-form-input .ant-input-number-input {
    font-weight: 600 !important;
}
.app-form-input.ant-select,
.app-form-input .ant-select {
    color: var(--formFontColor);
}
.app-form-search-option.ant-select {
    color: var(--formFontColor);
}
.app-form-input .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: var(--formFontColor);
}
.app-form-search-option .ant-select-selection-item,
.app-form-input .ant-select-selection-item {
    font-weight: 600;
}
.app-form-textarea {
    width: 100%;
    color: rgba(41, 55, 95, 0.8);
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 15px;
    font-weight: 600;
    padding: 0px 1rem;
    margin-bottom: 0;
    background: #fff;
    border: 1px solid rgba(58, 68, 59, 0.3);
    border-radius: 3px;
}
.app-form-textarea:hover,
.app-form-textarea:focus,
.app-form-textarea:active {
    border: 1px solid rgba(58, 68, 59, 0.3);
}
.app-form-input.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.app-form-input.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.app-form-search-option.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.app-form-search-option.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: rgba(58, 68, 59, 0.3);
}
/*************************************************************************WEB-TYPE-7**/
.app-primary-form-input.ant-input,
.app-primary-form-input.ant-input-password {
    width: 100%;
    height: 32px;
    /* color: #b0b4b1; */
    color: var(--Adminprimarydark);
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
    padding: 0px 1rem;
    margin-bottom: 0;
    background: #fff;
    border: 1px solid var(--Adminprimarydark);
    border-radius: 5px;
}
.app-primary-form-input.ant-input:hover {
    border-color: var(--Adminprimarydark);
    border-right-width: 1px;
}
.app-form-search-option.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.app-form-number {
    display: block;
}
/***************************************************************************/